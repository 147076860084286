<template>
  <div class="news-detail">
    <!-- <div class="title gn-flex-center">{{topTitle}}</div> -->
    <div class="title news-title gn-box-border gn-full-x gn-flex-center">{{detail.title}}</div>
    <div class="main" v-html="content"></div>
    <div class="bottom">
      <div class="card">
        <span>相关新闻</span>
      </div>
      <div class="otherList">
        <div class="content">
          <ul>
            <li
              v-for="(item, index) in otherList"
              :key="index"
              @click="toMoreNewPage(item)"
            >
              <div class="video">
                <img :src="item.title_image" class="gn-full" />
              </div>
              <div class="info">
                <div class="title gn-line-1">{{ item.title }}</div>
                <div class="time">{{ item.create_time }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="more gn-full-x gn-flex-center">
        <div class="btn gn-flex-center" @click="lookMore">查看更多</div>
      </div> -->
    <load-more :status='"loadmore"' @load="lookMore"></load-more>

    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'

export default {
  metaInfo () {
    return {
      title: this.$route.query.title
    }
  },
  data () {
    return {
      reqParams: {
        page: 1,
        per_page: 10,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      advisory_type: '1',
      title: '',
      detail: {},
      content: '',
      otherList: []
    }
  },
  created () {
    const { type, title } = this.$route.query

    this.advisory_type = type
    this.title = title

    this.getData()
    this.getOtherNews()
  },
  computed: {
    topTitle () {
      let text = ''

      if (this.advisory_type === '1') { // 公司新闻
        text = '公司新闻'
      } else if (this.advisory_type === '2') { // 行业动态
        text = '行业动态'
      }
      return text
    }
  },
  methods: {
    async getData () {
      this.reqParams.search_data = this.title
      this.reqParams.advisory_type = this.advisory_type

      const { list } = await getArticleList(this.reqParams)
      this.detail = (list || [])[0]
      this.content = (list || [])[0].content
    },
    async getOtherNews () {
      this.reqParams.advisory_type = this.advisory_type
      this.reqParams.search_data = ''
      const { list } = await getArticleList(this.reqParams)
      list.length >= 4 && (list.length = 4)
      this.otherList = list
    },
    async toMoreNewPage (item) {
      this.reqParams.search_data = item.title
      this.reqParams.advisory_type = item.type

      const { list } = await getArticleList(this.reqParams)
      this.detail = (list || [])[0]
      this.content = (list || [])[0].content

      window.scrollTo(0, 0)

      // 此段代码的目的仅仅是为了切换浏览器地址栏的参数信息
      this.$router.push({
        name: 'mobileNewsDetail',
        query: {
          type: item.type,
          title: item.title
        }
      })
    },
    lookMore () {
      this.$router.push({
        name: 'mobileNews',
        query: {
          type: (+this.advisory_type - 1).toString()
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  ::v-deep .more .text{
    font-size: 14px !important;
    i{
      font-size: 14px !important;
    }
  }
.news-detail{
  margin-top: 78px;
  .title{
    font-size: 20px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .news-title{
    font-size: 20px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    // border-top: 1px solid #F1F1F1;
    color: #333333;
  }
  .main{
    padding: 20px 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid #F1F1F1;
  }
  :deep(.main p font){
    font-size: 16px !important;
    color: #666666;
  }
  .bottom{
    .card {
      display: flex;
      justify-content: center;
      margin-bottom: 28px;
      span {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        padding: 43px 0px 4px 0px;
        color: #333333;
        border-bottom: 2px solid #00a0ac;
        box-sizing: border-box;
      }
    }
    .otherList {
      padding: 0px 10px;
      padding-bottom: 10px;
      .content {
        width: 100%;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 48.5%;
            height: auto;
            margin-bottom: 10px;
            .video {
              width: 100%;
              height: 90px;
              img {
                width: 100%;
                height: 100%;
              }
              .icon {
                width: 39px;
                height: 39px;
              }
            }
            .info {
              width: 100%;
              background: #F5F5F5;
              height: auto;
              padding-left: 15px;
              padding-top: 10px;
              padding-bottom: 15px;
              box-sizing: border-box;
              margin-bottom: 5px;
              .title {
                font-size: 14px;
                color: #333333;
                padding: 0;
                margin-bottom: 5px;
              }
              .time {
                font-size: 10px;
                color: #666666;
                padding: 0;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
    .more{
      margin-bottom: 30px;
      .btn{
        width: 94px;
        height: 30px;
        border: 1px solid #333333;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
    }
  }
}
</style>
